var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "side-bar-item"
    }
  }, [_vm.menu.type === 1 ? _c("el-submenu", {
    key: _vm.menu.path,
    attrs: {
      index: _vm.menu.path
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.menu.name))])]), _vm.menu.children && _vm.menu.children.length > 0 ? _vm._l(_vm.menu.children, function (innerMenu, innerIdx) {
    return _c("side-bar-item", {
      key: innerIdx,
      attrs: {
        menu: innerMenu
      }
    });
  }) : _vm._e()], 2) : _c("el-menu-item", {
    key: _vm.menu.path,
    attrs: {
      index: _vm.menu.path
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.menu.name))])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };