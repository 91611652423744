import mHeader from './header';
import mFooter from './footer';
import appMain from './appMain';
export default {
  name: "",
  components: {
    mHeader,
    mFooter,
    appMain
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=4abe7c70-2e4c-11e8-a186-8b8aab4e1c07&autoShow=true&language=ZHCN';
    document.head.appendChild(script);
  }
};