import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
const mapText = {
  society_account: ['社交平台账号', '', '社交平臺帳號'],
  terms_of_service: ['服务条款', 'Terms of Service', '服務條款'],
  intellectual_property: ['知识产权声明', 'Intellectual Property Statement', '知識產權聲明'],
  privacy_statement: ['隐私声明', 'Privacy Statement', '隱私聲明']
};
export default {
  name: "",
  data() {
    return {
      list: [{
        name: '关于我们',
        nameEn: 'ABOUT US',
        nameTr: '關於諾輝',
        path: '/about_company'
      }, {
        name: '产品体系',
        nameEn: 'OUR PRODUCTS',
        nameTr: '產品體系',
        path: '/product'
      }, {
        name: '用户故事',
        nameEn: 'PATIENT STORIES',
        nameTr: '用戶故事',
        path: '/user_story'
      }, {
        name: '新闻中心',
        nameEn: 'NEWS ROOM',
        nameTr: '新聞中心',
        path: '/news_activity'
      }, {
        name: '投资者关系',
        nameEn: 'INVESTORS RELATIONS',
        nameTr: '投資者關係',
        path: '/companyInformation'
      }, {
        name: '加入我们',
        nameEn: 'JOIN OUR TEAM',
        nameTr: '加入我們',
        path: '/wx_recruit',
        value: 'wx_recruit'
      }],
      mapText
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  computed: {
    ...mapState({
      language: state => state.language,
      device: state => state.device
    })
  },
  methods: {
    changeRoute(item) {
      console.log('🚀 ~ item', item);
      const path = item.path;
      if (path === this.$route.path) return;
      if (item.value == 'wx_recruit') {
        let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259';
        // let href = 'https://app.mokahr.com/social-recruitment/nhbio/42077'
        window.open(href, '_blank');
        return;
      }
      this.$router.push({
        path: path
      });
    },
    changeRouteChild(parent, child) {
      if (child.value == 'product_detail') {
        this.$router.push({
          path: child.path,
          query: {
            productName: child.name
          }
        }, onComplete => {
          this.show_list = !this.show_list;
        });
      } else if (child.value == 'wx_recruit') {
        let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259';
        // let href = 'https://app.mokahr.com/social-recruitment/nhbio/42077'
        window.open(href, '_blank');
      } else if (child.path == '/about_company') {
        this.$router.push({
          path: child.path,
          query: {
            hash: child.value
          }
        }, onComplete => {
          this.show_list = !this.show_list;
        });
      } else {
        this.$router.push({
          path: child.path
        }, onComplete => {
          this.show_list = !this.show_list;
        });
      }
    },
    jumpTo(path) {
      window.open(path, '_blank');
    },
    handleScroll() {
      // footer
      const footer = this.$refs.footer.getBoundingClientRect().bottom;
      const isLaptop = window.matchMedia('(max-width:1280px)').matches;
      this.changeClass(footer, this.$refs.logo_img, 'animate__fadeInLeft', isLaptop ? 700 : 1000);
      this.changeClass(footer, this.$refs.society_logos, 'animate__fadeInDown', isLaptop ? 700 : 1000);
      this.changeClass(footer, this.$refs.left_text, 'animate__fadeInLeft', isLaptop ? 700 : 1000);
      this.changeClass(footer, this.$refs.right_text, 'animate__fadeInUp', isLaptop ? 700 : 1000);
    },
    changeClass(distance, target, animate, top) {
      if (distance < top) {
        target.classList.add(animate);
        target.classList.remove('display-none');
      }
    }
  }
};