// const title = 'http://10.75.72.28:9196/'
// const title = 'http://192.168.90.147/'
// const title = 'http://www.newhorizonbio.com/'
const title = location.origin + '/';
const api = {
  //首页banner
  cmsHome_bannerList: title + 'master/cmsHome/bannerList',
  //首页卡片
  hometitleList: title + 'master/hometitle/list',
  //首页产品
  cmsHome_productList: title + 'master/cmsHome/productList',
  //首页链接内容
  cmsHomeNewsList: title + 'master/CmsHomeNews/list',
  //公司介绍
  introduce_introduceList: title + 'master/introduce/introduceList',
  //发展历程
  development_developmentList: title + 'master/development/developmentList',
  developmentPic_developmentPicList: title + 'master/developmentPic/developmentPicList',
  //管理层
  managementList: title + '/master/CmsLoader/list',
  //公司荣誉
  honor_honorList: title + 'master/honor/honorList',
  //资质证书
  certificate_certificateList: title + 'master/certificate/certificateList',
  //产品体系
  // product_getProduct:title + '/master/product/getProduct',
  product_list: title + '/master/nhProductNew/list',
  //用户故事
  story_userStoryList: title + '/master/story/userStoryList',
  storyHeader_userStoryHeaderList: title + 'master/storyHeader/userStoryHeaderList',
  storyDetail: title + 'master/story/detail',
  storyHeadDetail: title + 'master/storyHeader/userStoryHeaderDetail',
  //常见问题
  question_list: title + '/master/question/list',
  //杭州地址
  contact_hzList: title + '/master/contact/hzList',
  //北京地址
  contact_bjList: title + '/master/contact/bjList',
  // 区域办公室
  contact_officeList: title + '/master/contact/officeList',
  //社会监督
  contact_societyList: title + '/master/contact/societyList',
  //公司新闻
  news_gsList: title + '/master/news/gsList',
  //社会责任
  news_shList: title + '/master/news/shList',
  //企业文化
  news_whList: title + '/master/news/whList',
  //新闻详情
  news_detail: title + 'master/news/detail',
  //IR公司资料
  investment_companyInfo: title + 'master/investment/companyInfo',
  //招股文件
  investment_offering: title + 'master/investment/offering',
  //公告通函文件
  investment_postFileSearch: title + 'master/investment/postFileSearch',
  //企业管治人员
  investment_postManagerPersonSearch: title + 'master/investment/postManagerPersonSearch',
  //企业管治文件
  investment_postManagerFileSearch: title + '/master/investment/postManagerFileSearch',
  //邮箱订阅
  email_SubscribeMail: title + '/master/email/SubscribeMail',
  //取消邮箱订阅
  email_ExitSubscribeMail: title + '/master/email/ExitSubscribeMail',
  // 投资者日历列表查询
  investment_eventList: title + '/master/events/nearlist',
  // ESG
  esg_medicalList: title + 'master/medical/list',
  esg_honorList: title + 'master/esghonor/list',
  esg_reportList: title + 'master/esgreport/list',
  // 投资者月报
  monthly_reportList: title + 'master/newspaper/list',
  api_title: title
};
export default api;