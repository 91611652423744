var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-main",
    class: _vm.indexMenu == "/index" ? "app-main-index" : ""
  }, [_c("router-view")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };