import { mapState } from 'vuex';
export default {
  name: "appMain",
  data() {
    return {
      code_img: '',
      phone: '',
      timer: null,
      show_float: true,
      // scrollTop: "",
      goTopShow: false,
      float_data: {
        pictureAddress: ''
      }
    };
  },
  created() {
    // this.getData()
    // this.getDialog()
    // this.ratio()
  },
  watch: {
    // scrollTop() {
    //    if(this.scrollTop > 500) {
    //         this.goTopShow = true;
    //     } else {
    //         this.goTopShow = false;
    //     }
    // }
  },
  computed: {
    indexMenu() {
      const route = this.$route;
      return route.path;
    },
    ...mapState({
      device: state => state.device
    })
  },
  mounted() {},
  // 销毁页面滚动
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 页面滚动
    ratio() {
      let t = window.devicePixelRatio;
      console.log("devicePixelRatio=" + t);
      if (t != 1) {
        alert('您的设备对显示进行放大或者缩放导致页面显示不完全，请调整后浏览');
      }
    },
    handleScroll() {
      // this.scrollTop =
      //        window.pageYOffset ||
      //     document.documentElement.scrollTop ||
      //     document.body.scrollTop;
      // if (this.scrollTop > 500) {
      //         this.goTopShow = true;
      //     }
    },
    // 回到顶部
    goTop() {
      //  let timer = null,
      //        _that = this;
      //  cancelAnimationFrame(timer);
      // timer = requestAnimationFrame(function fn() {
      //     if (_that.scrollTop > 0) {
      //             _that.scrollTop -= 250;
      //             document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
      //              timer = requestAnimationFrame(fn);
      //          } else {
      //             cancelAnimationFrame(timer);
      //              _that.goTopShow = false;
      //          }
      //  });
    },
    // 浮窗动画
    animateInit() {
      // let box=document.getElementById('box');
      // let xSpeed=1,ySpeed=1
      // this.timer = setInterval(function(){
      //     let x=parseInt(box.style.left)+xSpeed;
      //     let y=parseInt(box.style.top)+ySpeed;
      //     //给动画设置边界
      //     if(x>(document.body.offsetWidth -250 )) xSpeed*=-1;
      //     else if(x<0) xSpeed*=-1;
      //     if(y> document.body.offsetHeight - 200) ySpeed*=-1;
      //     else if(y<120) ySpeed*=-1;
      //     box.style.left=x+'px';
      //     box.style.top=y+'px';
      // },1000/40);
    },
    // 鼠标移上动画暂停
    hoverStop() {
      // clearInterval(this.timer)
      // this.timer = null
    },
    // 点击关闭
    closeFloat() {
      // this.hoverStop()
      // this.show_float = false
    },
    // 获取联系我们
    getData() {
      // this.$http.get(this.$api.front_module_get+'/联系我们/pc').then(res => {
      //     if(res.code == 0){
      //         if(res.data){
      //             let data = res.data.pictureAddress
      //             this.code_img = data
      //             this.phone = res.data.note
      //         }
      //     }
      // })
    },
    // 获取广告
    getDialog() {
      // this.$http.get(this.$api.front_article_get_new).then(res => {
      //     if(res.code == 0){
      //         this.float_data = res.data
      //         this.animateInit()
      //         // 监听页面滚动
      //         window.addEventListener('scroll', this.handleScroll);
      //     }
      // })
    }
    // // 点击广告弹框
    // changeRoute(){
    //     this.show_float = false
    //     let path = '/continuingEducation'
    //     switch (this.float_data.module) {
    //         case '科普患教':
    //             path = '/popularization'
    //             break
    //         case '继续教育':
    //             path = '/continuingEducation'
    //             break
    //         case '成功病例展示':
    //             path = '/success'
    //             break
    //         case 'NOSES专区':
    //             path = '/noses'
    //             break
    //         case '中华结直肠疾病电子杂志':
    //             path = '/wait'
    //             break

    //     }
    //     this.$router.push({path:'/articleDetail',query:{last:JSON.stringify({
    //                 name:this.float_data.contentType,
    //                 value:this.float_data.contentType
    //             }),path:path,module:this.float_data.module,params:JSON.stringify(this.float_data),page_num:1,page_size:5,index:1 }})
    // }
  }
};