(function (doc, win) {
  var dpr = window.devicePixelRatio || 1;
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      // 当前页面宽度相对于1280宽的缩放比例，可根据自己需要修改。
      // 屏幕1280*分辨率下40px=1rem
      docEl.style.fontSize = 40 * (clientWidth / 1280) + 'px';
      // 设置data-dpr属性，留作的css hack之用
      docEl.setAttribute('data-dpr', dpr);
      // var delObj = document.getElementById('loading')
      // if(delObj){
      //     $('#loading').remove()
      // }
    };
  recalc();
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);