import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout/index';
import Layout_hk from '@/layout/index_hk';
export const constantRoutes = [{
  path: '/',
  component: Layout,
  meta: {},
  redirect: '/index',
  children: [{
    path: '/index_old',
    name: 'indexOld',
    component: () => import('@/views/index_oldVersion'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/about_company',
    name: 'about_company',
    component: () => import('@/views/about_company'),
    meta: {
      title: '公司介绍'
    }
  }, {
    path: '/development_history',
    name: 'development_history',
    component: () => import('@/views/development_history'),
    meta: {
      title: '发展历程'
    }
  }, {
    path: '/management',
    name: 'management',
    component: () => import('@/views/management'),
    meta: {
      title: '管理层'
    }
  }, {
    path: '/innovation_lead',
    name: 'innovation_lead',
    component: () => import('@/views/innovation_lead'),
    meta: {
      title: '科研学术'
    }
  }, {
    path: '/company_honor',
    name: 'company_honor',
    component: () => import('@/views/company_honor'),
    meta: {
      title: '公司荣誉'
    }
  }, {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product'),
    meta: {
      title: '产品聚合'
    }
  }, {
    path: '/product_detail',
    name: 'product_detail',
    component: () => import('@/views/product_detail'),
    meta: {
      title: '产品体系'
    }
  }, {
    path: '/user_story',
    name: 'user_story',
    component: () => import('@/views/user_story'),
    meta: {
      title: '用户故事'
    }
  }, {
    path: '/about_question',
    name: 'about_question',
    component: () => import('@/views/about_question'),
    meta: {
      title: '常见问题'
    }
  }, {
    path: '/about_contact',
    name: 'about_contact',
    component: () => import('@/views/about_contact'),
    meta: {
      title: '联系我们'
    }
  }, {
    path: '/corporate_culture',
    name: 'corporate_culture',
    component: () => import('@/views/corporate_culture'),
    meta: {
      title: '企业文化'
    }
  }, {
    path: '/wx_recruit',
    name: 'wx_recruit',
    component: () => import('@/views/wx_recruit'),
    meta: {
      title: '公众号招聘平台'
    }
  }, {
    path: '/qualification',
    name: 'qualification',
    component: () => import('@/views/qualification'),
    meta: {
      title: '资质证书'
    }
  }, {
    path: '/companyInformation',
    name: 'companyInformation',
    component: () => import('@/views/companyInformation'),
    meta: {
      title: '公司资料'
    }
  }, {
    path: '/offering',
    name: 'offering',
    component: () => import('@/views/offering'),
    meta: {
      title: '招股文件'
    }
  }, {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/notice'),
    meta: {
      title: '公告及通函'
    }
  }, {
    path: '/governance',
    name: 'governance',
    component: () => import('@/views/governance'),
    meta: {
      title: '企业管治'
    }
  }, {
    path: '/stock',
    name: 'stock',
    component: () => import('@/views/stock'),
    meta: {
      title: '股票信息'
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact'),
    meta: {
      title: '联系方式'
    }
  }, {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service'),
    meta: {
      title: '服务条款'
    }
  }, {
    path: '/property',
    name: 'property',
    component: () => import('@/views/property'),
    meta: {
      title: '知识产权声明'
    }
  }, {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy'),
    meta: {
      title: '隐私声明'
    }
  }, {
    path: '/news_activity',
    name: 'news_activity',
    component: () => import('@/views/news_activity'),
    meta: {
      title: '公司新闻'
    }
  }, {
    path: '/support_public_welfare',
    name: 'support_public_welfare',
    component: () => import('@/views/support_public_welfare'),
    meta: {
      title: '社会责任'
    }
  }, {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () => import('@/views/articleDetail'),
    meta: {
      title: '新闻详情页'
    }
  }, {
    path: '/storyDetail',
    name: 'storyDetail',
    component: () => import('@/views/storyDetail'),
    meta: {
      title: '用户故事详情页'
    }
  }, {
    path: '/storyHeadDetail',
    name: 'storyHeadDetail',
    component: () => import('@/views/storyHeadDetail'),
    meta: {
      title: '用户故事详情页'
    }
  }, {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import('@/views/unsubscribe'),
    meta: {
      title: '取消订阅'
    }
  }, {
    path: '/event',
    name: 'event',
    component: () => import('@/views/event'),
    meta: {
      title: '投资者日历'
    }
  }, {
    path: '/monthlyReport',
    name: 'monthlyReport',
    component: () => import('@/views/monthlyReport'),
    meta: {
      title: '投资者月报'
    }
  }, {
    path: '/eventDetail',
    name: 'eventDetail',
    component: () => import('@/views/eventDetail'),
    meta: {
      title: '活动详情'
    }
  }, {
    path: '/esg',
    name: 'esg',
    component: () => import('@/views/esg/index'),
    meta: {
      title: '可持续发展'
    }
  }]
}, {
  path: '/trustNh',
  name: 'trustNh',
  component: () => import('@/views/trustNh'),
  meta: {
    title: 'Why NH'
  }
}];
const hkRoutes = [{
  path: '/index_hk',
  redirect: '/index_hk',
  component: Layout_hk,
  meta: {},
  children: [{
    path: '/index_hk',
    name: 'hk_index',
    component: () => import('@/hkViews/index'),
    meta: {
      title: 'ColoClear by Circle'
    }
  }]
}];
const createRouter = () => new Router({
  // mode:'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [...constantRoutes, ...hkRoutes]
});
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = createRouter();
export default router;