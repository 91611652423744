import request from '@/utils/request';
import api from './api';
export function manageLogin(data) {
  return request({
    url: api.manage_login,
    method: 'post',
    data
  });
}
export function frontLogin(data) {
  return request({
    url: api.front_login,
    method: 'post',
    data
  });
}
export function getInfo(loginName) {
  return request({
    url: api.frontTK_member_get + '/' + loginName,
    method: 'get'
  });
}

// export function logout() {
//   return request({
//     url: api.user_logout,
//     method: 'DELETE'
//   })
// }