import Vue from 'vue';
import Vuex from 'vuex';
import { getToken, setToken, removeToken } from '../common/cache.js';
import Cookies from 'js-cookie';
import { manageLogin, frontLogin, getInfo } from '@/api/user';
import router, { resetRouter } from '@/router';

// const naviLanguage = navigator.systemLanguange || navigator.language
// let naviLanguageType = 1
// if (naviLanguage === 'en') {
//   naviLanguageType = 2
// } else if (naviLanguage === 'zh-CN' || naviLanguage ==='zh') {
//   naviLanguageType = 1
// } else {
//   naviLanguageType = 3
// }
Vue.use(Vuex);
const user_account = getToken("front_token"); // 获取用户信息
const userName = getToken('username');
const info = getToken('info') && JSON.parse(getToken('info'));
const store = new Vuex.Store({
  state: {
    hasLogin: user_account ? true : false,
    // 登录状态
    loginProvider: "",
    userInfo: userName ? {
      username: userName
    } : {},
    // 用户信息
    params: {},
    irMenuData: [
    // {
    // 	name:'公司资料',
    // 	nameEn:'Corporate Information',
    // 	nameTr:'公司資料',
    // 	path:'/companyInformation',
    // 	value:'companyInformation'
    // },
    {
      name: '投资者日历',
      nameEn: 'Events Calendar',
      nameTr: '活動日曆',
      path: '/event',
      value: 'event'
    }, {
      name: '投资者月报',
      nameEn: 'Investor Monthly Report',
      nameTr: '投資者月報',
      path: '/monthlyReport',
      value: 'monthlyReport'
    }, {
      name: '招股文件',
      nameEn: 'Listing Documents',
      nameTr: '招股文件',
      path: '/offering',
      value: 'offering'
    }, {
      name: '公告及通函',
      nameEn: 'Announcements & Notices',
      nameTr: '公告及通函',
      path: '/notice',
      value: 'notice'
    }, {
      name: '企业管治',
      nameEn: 'Corporate Governance',
      nameTr: '企業管治',
      path: '/governance',
      value: 'governance'
    }, {
      name: '股票信息',
      nameEn: 'Stock Information',
      nameTr: '股票資訊',
      path: '/stock',
      value: 'stock'
    }, {
      name: '联系方式',
      nameEn: 'IR Contact',
      nameTr: '聯繫方式',
      path: '/contact',
      value: 'contact'
    }],
    // 中英文
    // language: Cookies.get('language') || 'zh',
    language: localStorage.getItem('language') || '1',
    device: 'pc',
    token: '',
    info: info || {},
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
      withoutAnimation: false
    },
    size: Cookies.get('size') || 'medium',
    tagsView: true,
    showLogin: false
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem('language', language);
    },
    // login(state, user) {
    // 	state.hasLogin = true;
    // 	state.userInfo = user;
    // 	// setToken("token", user); // 缓存用户信息
    // },
    // logout(state) {
    // 	state.hasLogin = false;
    // 	state.userInfo = {};
    // 	state.info = {};
    // },
    // showLogin(state,bool){
    // 	state.showLogin = bool == 'false'?false : !state.showLogin
    // },
    // getUserInfo(state) {
    //     return getToken("token"); // 获取用户信息
    // },
    // 中英文
    // SET_LANGUAGE: (state, language) => {
    // 	state.language = language
    // 	Cookies.set('language', language)
    // },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_FRONT_TOKEN: (state, token) => {
      state.front_token = token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    SET_SIZE: (state, size) => {
      state.size = size;
      Cookies.set('size', size);
    }
  },
  actions: {
    toggleSideBar({
      commit
    }) {
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar({
      commit
    }, {
      withoutAnimation
    }) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    setSize({
      commit
    }, size) {
      commit('SET_SIZE', size);
    },
    // 中英文
    setLanguage({
      commit
    }, language) {
      commit('SET_LANGUAGE', language);
    },
    toggleDevice({
      commit
    }, device) {
      commit('TOGGLE_DEVICE', device);
    },
    // user login
    login({
      commit
    }, userInfo) {
      const {
        username,
        password,
        memberType
      } = userInfo;
      return new Promise((resolve, reject) => {
        frontLogin({
          username: username.trim(),
          password: password,
          memberType: memberType
        }).then(response => {
          commit('login', {
            username: username.trim()
          });
          commit('SET_FRONT_TOKEN', response.data);
          setToken('front_token', response.data);
          setToken('username', username.trim());
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    getInfo({
      commit
    }, userInfo) {
      const {
        username
      } = userInfo;
      return new Promise((resolve, reject) => {
        getInfo(username.trim()).then(response => {
          commit('SET_INFO', response.data);
          // commit('SET_FRONT_TOKEN', response.data)
          setToken('info', JSON.stringify(response.data));
          // setToken('username',username.trim())
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // user logout
    logout({
      commit,
      state,
      dispatch
    }) {
      // return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      commit('SET_FRONT_TOKEN', '');
      commit('logout', []);
      removeToken('front_token');
      removeToken('info');
      removeToken('username');
      commit('SET_TOKEN', '');
      removeToken('token');
      // resetRouter()

      // dispatch('tagsView/delAllViews', null, { root: true })

      // resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      // })
    },
    backLogin({
      commit
    }, userInfo) {
      const {
        username,
        password
      } = userInfo;
      return new Promise((resolve, reject) => {
        manageLogin({
          username: username.trim(),
          password: password
        }).then(response => {
          commit('SET_TOKEN', response.data);
          setToken('token', response.data);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    backLogout({
      commit,
      state,
      dispatch
    }) {
      // return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      commit('SET_TOKEN', '');
      removeToken();
      // resetRouter()

      // dispatch('tagsView/delAllViews', null, { root: true })

      // resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      // })
    }
  }
});
export default store;